@import "../../shared/stylesParams.module";

.logo {
  width: 180px;
  // height: 24px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
  }
}

.header_buttons_stack {
  width: 1155px;
  max-width: 90%;
}

.headerButton--selected {
  color: rgb(255, 178, 2) !important;
}

.container {
  padding: 48px 50px 48px 0px;
  align-items: center;
  position: relative;
}

.headerButton_text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $neutral_black;
  &:hover {
    color: $tertiary;
  }
}

.headerButton {
  height: 20px;
  min-width: unset;
  padding: 0px 0px 0px 0px;
  white-space: nowrap;
}

.headerButton_stack {
  column-gap: 52px;
  margin-left: 59.9px;
}

.loginLogoutButtonBase {
  padding: 10px 24px;
  width: 125px;
  height: 40px;
  border-radius: 22px;
}

.loginLogoutButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header_leftStack {
  column-gap: 32px;
  // margin-left: 200px;
  margin-left: auto;
}

.dropdownHeader_menuList {
  display: none;
}

.signupButton {
  @extend .loginLogoutButtonBase;
  background: $neutral_black !important;
  color: $neutral_white;
  &:hover {
    background: $neutral_30 !important;
  }
}

.loginButton {
  @extend .loginLogoutButtonBase;
  border: 0.2px $neutral_black solid;
  color: $neutral_black;
}

.dashboardButton {
  @extend .loginLogoutButtonBase;
  // background-image: linear-gradient(90deg, $secondary_80 0, $primary_50 50%, $primary_light);
  background-color: #006782;
  color: white;
}

.dashboardButton:hover {
  background-color: $secondaryBlue800;
}

.Header__appBar {
  box-shadow: unset !important;
  background: $neutral_white;
}

// .shopCart {
//   width: 200px;
//   height: 400px;
//   border: 1px solid black;
//   border-radius: 5px;
// }

.shopCartButton {
  position: relative;
}

.shopCartIcon {
  width: 48px;
  height: 48px;
  color: black;
}

.smallShopCartButton {
  position: fixed;
  right: 0;
  display: none;
}

.shopCartCount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  margin-left: 2px;
  color: $kepple_green;
}

.dropdownContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms;
  align-items: flex-start;
  >button {
    margin-left: 40px;
    color: $primaryPurple500;
  }
  >button:hover {
    background-color: $primaryPurple50;
  }
}

.dropdownContainer--open {
  max-height: fit-content;
}

@media screen and (max-width: 1240px) {
  .container {
    padding: 11.5px 0px 11.5px 0px;
    width: 100%;
  }

  .menuButton {
    color: $on_surface_variant;
    font-size: 35px;
    left: 0;
  }

  .shopCartCount {
    margin-top: -3px;
    margin-left: 2px;
  }

  .smallShopCartButton {
    display: unset;
  }

  .logo {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .menuItem {
    // height: 56px;
  }

  .DropdownHeaderButton {
    align-self: flex-start;
    color: $on_surface_variant;
  }

  .DropdownHeaderButton--selected {
    color: $secondary_10;
  }

  .dropdownHeader_menuList {
    width: 100vw;
    top: 78px !important;
    display: unset;
  }

  .dropdownHeader__paper {
    background: $neutral_white;
  }

  .DropdownHeaderButton__stack {
    column-gap: 14px;
  }

  .header_buttons_stack {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding: 7.5px 0px 7.5px 0px;
  }

  .shopCartIcon {
    width: 40px;
    height: 40px;
  }

  .shopCartCount {
    margin-left: 2px;
  }

  .dropdownHeader_menuList {
    width: 100vw;
    top: 70px !important;
  }
}


.dotButtonMenu{
  border-radius: 8px;
  border: 1px solid #F5F5F5;
}

.hoverMenuItem{
  flex:1;
  height: 44px;
  padding: 12px 24px;
  border-radius: 8px;

  color: $primaryPurple500;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  transition: all 300ms;
}

.hoverMenuItem:hover{
  background-color: $primaryPurple50;
}

.menuListcontainer{
  padding: 40px;
  gap: 12px;
  li:not(:last-child) { 
    margin-bottom: 12px;  
 }
}