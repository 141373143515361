@import "../../shared/stylesParams.module";

.previewPanel {
  width: 700px;
  height: 600px;
  z-index: 0;
}

.termsSelectionPanel {
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #f5f5f5;
}

.termsScrollBar {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  padding-top: 0px;
  row-gap: 18px;
}

.buttonWrapper--opened {
  padding-left: 15px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.buttonWrapper--closed {
  align-items: center;
  padding-top: 7.5px;
}

.previewPanelDeivider {
  font-style: italic;
  font-family: "Lexend Deca" !important;
  font-size: 14px !important;
  padding: 15px 0px 15px 0px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.termTitle {
  cursor: pointer;
  font-family: "Lexend Deca" !important;
  &:hover {
    color: $primary_60;
  }
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.termTitle--notSelected {
  color: $primary_10;
  font-size: 14px;
  font-weight: 500;
}

.termTitle--selected {
  color: $primary_light;
  font-size: 15px;
  font-weight: 500;
  text-decoration: 2px solid $primary_light underline;
  &:hover {
    text-decoration: 2px solid $primary_60 underline;
  }
}

.TableRowsIcon {
  display: none;
}

.upcomingTermsGroup {
  row-gap: 18px;
}

.iframeError {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: $neutral_50;
}

.loadingSpinnerWrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.PictureAsPdfIcon {
  font-size: 55px;
}

.CircularProgress {
  color: $primary_50;
}

@media screen and (max-width: 1240px) {
  .termsSelectionPanel {
    position: absolute;
    left: 0;
    width: unset;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
    height: 600px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 1;
    background-color: #f5f5f5;
    transition: 0.2s cubic-bezier(1, 0, 0, 1);
  }

  .termsSelectionPanel--closed {
    width: 70px;
  }

  .termsSelectionPanel--opened {
    width: 400px;
  }

  .termTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Number of lines to display before ellipsis */
    text-overflow: ellipsis;
  }

  .previewPanel {
    width: 630px;
    right: 0;
  }

  .TableRowsIcon {
    font-size: 26px;
    display: unset;
    cursor: pointer;
    &:hover {
      color: $primary_light;
    }
  }

  .termsContainer {
    width: 700px;
    position: relative;
    justify-content: end;
    z-index: 0;
  }
}

@media screen and (max-width: 600px) {
  .termsContainer {
    width: 380px;
  }

  .TableRowsIcon {
    font-size: 20px;
  }

  .previewPanel {
    width: 340px;
  }

  .scrollable {
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
  }

  .termsSelectionPanel--closed {
    width: 40px;
    align-items: center;
    overflow: hidden;
  }
}
