@import "../../shared/stylesParams.module";
.datePickerPopper {
    padding-left: 10%;
}
.datePickerAdornment {
    padding-right: 10px;
}
.datePickerErrorMsg {
    font-size: 12px !important;
    color: $destructiveRed500 !important;
}