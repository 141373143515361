// neutral
$neutral_black: #131313;
$neutral_10: #191c1e;
$neutral_white: #ffffff;
$neutral_50: #70787d;
$neutral_30: #444749;
$neutral_20: #2e3132;
$neutral_0: #444749;
$neutral_25: #393c3d;
$neutral-variant95: #eaf2f7;
$neutral_80: #c0c8cc;
$neutral_70: #a9abad;

// From Loop
$neutrals50: #F7F7F7;
$neutrals100: #f5f5f5;
$neutrals200: #e5e5e5;
$neutrals300: #d4d4d4;
$neutrals400: #a3a3a3;
$neutrals500: #737373;
$neutrals600: #525252;
$neutrals700: #404040;
$shades0: #fff;
$shades100: #555;

$textSecondary: rgba(0, 0, 0, 0.60);

// primary
$primary_60: #009dc5;
$primary_25: #004154;
$primary_light: #006782;
$primary_50: #0081a3;
$primary95: #dff4ff;
$primary_20: #003545;
$primary_10: #001f29;
$primary_70: #0cb9e8;
$primary_99: #fafcff;

// secondary
$secondary_10: #281900;
$secondary_80: #ffba3b;
$secondary_85: #fbbf66;
$secondary_90: #ffdead;
$secondary_98: #fff8f3;
$secondary_95: #ffeeda;
// tertiary
$tertiary_70: #ef89cf;
$tertiary_80: #ffade2;
$tertiary: #c465a8;
$tertiary_95: #ffecf4;
// others
$on_surface_variant: #40484c;
$error_red: #d94c4c;
// surface
$surface_1: linear-gradient(0deg, rgba(0, 103, 130, 0.05), rgba(0, 103, 130, 0.05)), #fbfcfe;
// light
$light_gray: #efefef;
$merchandies_bg_gray: #f3f3f3;
// kepple colours
$kepple_green: #02a674;
$kepple_orange_light: rgb(254, 197, 01);
$kepple_orange: rgb(252, 178, 24);
$kepple_purple_light: rgb(196, 101, 168);
$kepple_purple: rgb(165, 77, 157);
$header_pc_height: 120px;
$header_tablet_height: 80px;
$header_mobile_height: 72px;


// New Colors
$backgroundPaperElevation0: #fff;

$primaryBlue400: #2DCFF4;
$primaryBlue500: #2DC5F4;
$primaryBlue600: #53BDD9;


$secondaryBlue50: #DFF4FF;
$secondaryBlue300: #19A7F4;
$secondaryBlue700: #0095BD;
$secondaryBlue800: #0081A3;
$secondaryBlue900: #006D8A;
$secondaryBlue950: #005970;

$primaryYellow50: #FFF0BC;
$primaryYellow400: #FEC565;
$primaryYellow500: #FCB218;

$primaryPurple50: #F1D8EA;
$primaryPurple400: #C465A8;
$primaryPurple500: #A54D9D;
$primaryPurple600: #8C4185;

$secondaryGreen50: #DAEEE7;
$primaryGreen400: #60C1A3; 
$primaryGreen500: #02A674; 

$secondaryPink400: #F5A7A3;

$destructiveRed500: #EC5B53;

:export {
  neutral_black: $neutral_black;
  neutral_white: $neutral_white;
  neutral_10: $neutral_10;
  primary_60: $primary_60;
  secondary_80: $secondary_80;
  primary_light: $primary_light;
  tertiary_70: $tertiary_70;
  primary_25: $primary_25;
  primary95: $primary95;
  neutral_30: $neutral_30;
  primary_20: $primary_20;
  secondary_98: $secondary_98;
  secondary_90: $secondary_90;
  secondary_10: $secondary_10;
  primary_10: $primary_10;
  tertiary: $tertiary;
  neutral_0: $neutral_0;
  tertiary_95: $tertiary_95;
  primary_70: $primary_70;
  secondary_95: $secondary_95;
  surface_1: $surface_1;
  neutral_25: $neutral_25;
  neutral-variant95: $neutral-variant95;
  on_surface_variant: $on_surface_variant;
  neutral_50: $neutral_50;
  light_gray: $light_gray;
  error_red: $error_red;
  kepple_green: $kepple_green;
  merchandies_bg_gray: $merchandies_bg_gray;

  neutrals50: $neutrals50;
  neutrals100: $neutrals100;
  neutrals200: $neutrals200;
  neutrals300: $neutrals300;
  neutrals400: $neutrals400;
  neutrals500: $neutrals500;
  neutrals600: $neutrals600;
  neutrals700: $neutrals700;
  shades0: $shades0;
  shades100: $shades100;

  textSecondary: $textSecondary;

  backgroundPaperElevation0: $backgroundPaperElevation0;

  primaryBlue500: $primaryBlue500;
  primaryBlue600: $primaryBlue600;
  secondaryBlue300: $secondaryBlue300;
  secondaryBlue700: $secondaryBlue700;
  secondaryBlue800: $secondaryBlue800;
  secondaryBlue900: $secondaryBlue900;
  secondaryBlue950: $secondaryBlue950;

  primaryYellow50: $primaryYellow50;
  primaryYellow400: $primaryYellow400;
  primaryYellow500: $primaryYellow500;

  primaryPurple50: $primaryPurple50;
  primaryPurple400: $primaryPurple400;
  primaryPurple500: $primaryPurple500;
  primaryPurple600: $primaryPurple600;

  secondaryGreen50: $secondaryGreen50;
  primaryGreen400: $primaryGreen400;
  primaryGreen500: $primaryGreen500;

  secondaryPink400: $secondaryPink400;
  destructiveRed500: $destructiveRed500;
}
