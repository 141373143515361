@import "../../shared/stylesParams.module";

// .step {
//     // transition: height 300ms ease-in-out, width 300ms ease-in-out, background-color 300ms ease-in-out;
// }

.stepperContainer {
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    align-items: center;
    padding: 10px;
    height: 30px;
    width: 100%;
    margin-bottom: 12px;
}

.step {
    min-width: 9px;
    max-width: 10px;
}

.step__selected {
    min-width: 15px;
    max-width: 20px;
}

@media screen and (max-width: 800px) {
    .stepperContainer {
        justify-content: space-between;
        gap: 0;
        padding: 0;
    }
}