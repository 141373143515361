@import "../../shared/stylesParams.module";


.hydhTitleContainer {
    gap: 24px;
    align-items: center;
    width: 40%;

    img {
        width: 140px;
        height: 140px;
    }

    h1 {
        color: $shades0;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        /* 120% */
        letter-spacing: -0.8px;

        span {
            color: $primaryYellow500;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -0.8px;
        }
    }

    p {
        color: $shades0;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.32px;
    }
}

.hdyhForm {
    border-radius: 16px;
    background: $backgroundPaperElevation0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 40%;
    flex: 1;
    padding: 40px;
    gap: 24px;
}

.hydhBackground {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    z-index: -1;
}

.hdyhCloseBtn {
    position: absolute;
    top: 1%;
    right: 1%;
}

@media screen and (max-width: 600px) {
    .hydhTitleContainer {
        width: 100%;
    }

    .hdyhForm {
        width: 100%;
    }
}

.locationPopup {
    svg {
        width: 45px;
        height: auto;
    }
    h5 {
        color: $shades100;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.48px;
    }
    small {
        color: $shades100;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.28px;
    }
}