#AlertOnTopComponent__dialog {
  overflow: hidden;
}

#alert_on_top_component {
  width: 500px;
  min-height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#AlertOnTopComponent__DialogActions {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 0px !important;
}

#alert_dialog_title {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.AlertOnTopComponent__button--success {
  background-color: rgb(2, 166, 116) !important;
}

.cancelButton {
  background-color: #9d9d9d !important;
}

@media screen and (max-width: 780px) {
  #alert_on_top_component {
    width: 330px;
    font-size: 10px;
  }
}
