@import "../../shared/stylesParams.module";

.container {
  width: 928px;
  row-gap: 44px;
  align-items: center;
  justify-content: center;
}

.TermsOfServicePage {
  margin-bottom: 113px;
  margin-top: 55px;
}

.title {
  margin-bottom: 26px;
}

@media screen and (max-width: 600px) {
  .title {
    margin-bottom: -2px;
  }

  .container {
    row-gap: 29px;
  }
}
