// Styles that not build through styles module middleware
// But those styles are not reused
// Reused styles are in app.scss
@import "../shared/stylesParams.module";

.TeacherPageFifthContent {
  .swiper-button-next,
  .swiper-button-prev {
    height: 39.53125px !important;
    width: 22.552114486694336px !important;
    color: $neutral_20 !important;
  }
}

.BlogsPage {
  .swiper-button-next,
  .swiper-button-prev {
    height: 39.53125px !important;
    width: 22.552114486694336px !important;
    color: $neutral_20 !important;
  }
  .swiper-pagination {
    display: none;
  }
}

.dynamic_generate_blogs img {
  max-width: 768px;
}

// Styles for blog pages
#BlogPage {
  img {
    object-fit: contain;
    height: unset !important;
  }
}

// Header is in absolute postion, so page content need to set margin top value to avoid overlapping with header
.space_for_header {
  margin-top: $header_pc_height;
}

input {
  height: 100% !important;
}

.text_oneLine_ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Animations
.rotate_animation {
  animation: rotateAnimation 0.6s infinite;
  scale: 1.5;
}

// Same error description styles as mui TextField
.errorPrompt {
  color: rgb(211, 47, 47);
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(30deg);
  }
  66% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.fill_screen_height {
  min-height: calc(100vh - 309px - 120px);
}

// For hard coded blogs
@media screen and (max-width: 780px) {
  .dynamic_generate_blogs img {
    max-width: 300px;
  }

  .space_for_header {
    margin-top: $header_tablet_height;
  }
}

// For tablet screen
@media screen and (max-width: 1240px) {
  .fill_screen_height {
    min-height: calc(100vh - 220px - 80px);
  }
}

// For mobile screen
@media screen and (max-width: 600px) {
  .space_for_header {
    margin-top: $header_mobile_height;
  }

  .fill_screen_height {
    min-height: calc(100vh - 175px - 72px);
  }
}
