@import "../../../shared/stylesParams.module";

h4 { margin: 0; }
h6 { margin: 0; }
p { margin: 0; }

.mainContainer {
    position: relative;
    margin-top: 24px;
    margin-bottom: 72px;
    gap: 40px;
}

.titleContainer { 
    padding: 30px 20px;

    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 16px 16px 0 0;
    background: $primaryPurple500;
    text-align: center;
    h4 {
        color: $shades0;
        /* Heading/H4/Regular */
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        letter-spacing: -0.56px;
    }
    p {
        color: $shades0;
        /* Paragraph/Medium/Regular */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.32px;
    }
}

.content {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .contentTitle {
        padding: 12px 0px 12px 24px;
        align-items: center;
        border-radius: 8px 8px 0 0;
        background: $primaryPurple500;
        >p {
            color: $shades0;
            /* Paragraph/Large/Regular */
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
            letter-spacing: -0.36px;
        }
    }

    .contentData {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;

        .subtitle {
            color: $primaryPurple500;
        }
    }

    hr {
        margin: 0px 24px;
    }
}

.declaration {
    gap: 24px;
    >.title {
        gap: 12px;
        h6 {
            color: $shades100;
            /* Heading/H6/Regular */
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 140% */
            letter-spacing: -0.4px;
        }
        p {
            color: $neutrals500;

            /* Paragraph/Medium/Regular */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: -0.32px;
        }
    }

    .checkbox {
        flex-direction: row;
        padding: 24px;
        gap: 24px;
        align-items: center;
        border-radius: 16px;
        border: 1px solid $shades100;
        > small {
            color: $shades100;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.28px;
        }
    }

    .noLimitation {
        @extend .checkbox;
    }
    .noLimitation--checked {
        border: 1px solid $primaryGreen400;
        background: $secondaryGreen50;
    }

    .hasLimitation {
        @extend .checkbox;
    }

    .hasLimitation--checked {
        border: 1px solid $primaryYellow400;
        background: $primaryYellow50;
    }
}

.footer {
    // padding: 40px 80px;
    height: calc((100vh - 100px) * 0.15);
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    >button {
        min-width: 240px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
    }
}

.box {
    height: calc((100vh - 100px) * 0.85);
    overflow-y: scroll;
    overflow-x: hidden;
}

.yes {
    color: $primaryPurple500;
}

.yes--incomplete {
    color: $primaryYellow500;
}

.yes--submitted {
    color: $secondaryBlue800;
}

  
.imgContainer {
    overflow: hidden;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 0.5rem;
    img {
        position: absolute;
        width: auto;
        height: 100%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    @media screen and (max-width:600px) {
        width: 125px;
        height: 125px;
    }
}