@import "../../shared/stylesParams.module";

.mainContainer {
    height: 100%;
}

.labelContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    border: 1px dashed $neutrals400;
    background: $backgroundPaperElevation0;

    transition: all 300ms;

    padding: 40px 20px;
    cursor: pointer;

    >div {
        align-items: center;
    }

    p {
        color: $shades100;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: -0.24px;
    }

    small {
        color: $neutrals500;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: -0.24px;
    }

    .docPath--purple {
        fill: #A54D9D;
        transition: all 100ms;
    }

    svg {
        transition: all 300ms;
    }
}

.labelContainer.drag-active {
    background-color: $neutrals100;
}

.labelContainer:hover {
    svg {
        transform: scale(1.1);
    }

    // .docPath {
    //     // filter: invert(0.1);
    //     // fill:$secondaryBlue700;
    // }
    // .docPath--purple {
    //     fill: $primaryPurple400;
    // }
}

.labelContainer-disabled {
    background: $neutrals100;
    border: 1px dashed $neutrals200;
    cursor: default;

    p,
    small,
    .docPath,
    .docPath--purple,
    svg,
    path {
        filter: opacity(0.6);
    }
}

.labelContainer--withfile {
    cursor: default;
}

.pictureContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    background: $backgroundPaperElevation0;

    min-height: 200px;

    >div {
        align-items: center;
    }

    p {
        color: $shades100;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: -0.24px;
    }

    small {
        color: $neutrals500;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: -0.24px;
    }
}

.pictureContainer--noPicture {
    @extend .pictureContainer;
    border: 1px dashed $neutrals400;
}

.pictureBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shades0;
    opacity: 0;
    transition: all 100ms;

    align-items: center;
    justify-content: center;
}

.pictureBackdrop:hover {
    opacity: 0.5;
}

// PhotoUpload Styles

.uploadedImage {
    align-items: center;
    width: 100%;
    height: 100%;

    justify-content: space-between;

    .imgContainer {
        overflow: hidden;
        position: relative;
        // width: 300px;
        // height: 300px;
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;

        img {
            position: absolute;
            width: auto;
            height: 100%;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}

.uploadPictureBox {
    @extend .labelContainer;
    height: 100%;
    cursor: pointer;
    border-radius: 0.5rem;
    border: 1px dashed var(--neutrals-400, #A3A3A3);
    background: #FFF;
    padding: 10px;
    align-items: center;
    justify-content: center;

    small {
        font-size: 14px;
    }

    .imgContainer {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        border-radius: 0.5rem;
        border: 1px dashed $neutrals400;
        // display: none;
        // @media screen and (min-width:1300px) {
        //   display: block;
        // }

        img {
            position: absolute;
            width: auto;
            height: 100%;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}

.pictureCropBox {
    position: relative;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    background-color: $neutrals50;
    padding-top: 20px;
    padding-bottom: 20px;
}

.pictureCropBoxContainer {
    position: relative;
    width: 356px;
    height: 356px;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px dashed var(--neutrals-400, #A3A3A3);
}

.cropContainer {
    margin: auto;
    width: 350px;
    height: 350px;
    border-radius: 0.5rem;
}

.zoomSlider {
    // color: var(--neutrals-500, #737373);
    color: $primaryBlue500;
    width: 200px;
    height: 6px;
    border-radius: 8px;
}

.ZoomLabel {
    color: $shades100;
    text-align: center;

    /* Paragraph/Small/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;
}

.editModal {
    >button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }

    >div {
        // height: 100%;
        height: fit-content;
        padding: 60px;
        gap: 24px;
        align-items: flex-start;

        @media screen and (max-width:600px) {
            padding: 20px;
        }
    }

    .editModalBtns {
        width: 100%;
        height: fit-content;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width:600px) {
            flex-direction: column;
            gap: 10px;

            >* {
                width: 100%;
            }
        }
    }

    .photoEdit {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 40px;
        flex-wrap: wrap;
        margin: 0;

        @media screen and (max-width:600px) {
            flex-direction: column;
            gap: 10px;
        }
    }
}