@import "../../../shared/stylesParams.module";

.h4 {
    color: $shades0;
    text-align: center;

    /* Heading/H4/Regular */
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 128.571% */
    letter-spacing: -0.56px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.h5 {
    margin: 0;
    color: $shades100;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.48px;

    @media screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.p {
    color: $neutrals500;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;

    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.scrollContainer {
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (max-height:1200px) {
        justify-content: flex-start;
    }
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

.topBar {
    height: 84px;
    width: 100%;
    background-color: $primaryYellow500;
    align-items: center;
    justify-content: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    position: relative;

    button {
        position: absolute;
        left: 24px;
    }

    @media screen and (max-width: 600px) {
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 54px;
        height: fit-content;

        button {
            position: relative;
            height: 40px;
            width: 40px;
            left: 0;
        }

        h4 {
            flex-grow: 1;
            font-size: 16px;
        }
    }

    @media screen and (max-height: 1000px) {
        height: 8%;
        min-height: 54px;
    }
}

.topBar--rejected {
    @extend .topBar;
    background-color: $secondaryPink400;
}

.topBar--profile {
    @extend .topBar;
    height: 86px;
    background-color: $secondaryBlue800;
}

.topBar--school {
    @extend .topBar;
    background-color: $primaryPurple400;
}

.footerBar {
    padding: 0 40px 40px;

    hr {
        margin-bottom: 40px;
        width: 100%;
    }

    button {
        height: 36px;
        max-height: 36px;
    }

    @media screen and (max-width: 600px) {
        padding: 10px 30px;

        >div {
            gap: 10px;
        }

        //         padding: 0 30px 24px;
        hr {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-height: 1000px) {
        padding: 0 40px 24px;

        hr {
            margin-bottom: 24px;
        }
    }
}

.btn {
    flex-grow: 0;

    @media screen and (max-width: 600px) {
        flex-grow: 1;
    }
}

.nextBtn {
    @extend .btn;
    display: flex;
    min-width: 164px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    color: $shades0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;
}

.backBtn {
    @extend .btn;
    // margin-right: auto;
    // @media screen and (max-width: 600px) {
    //     margin-right: 0;        
    // }
}

.saveBtn {
    @extend .btn;
}

.titleContainer {
    gap: 12px;

    >.infoBox {
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;

        >small {
            color: $neutrals500;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

        padding: 24px;
        border-radius: 8px;
        background-color: $neutrals50;
    }
}

.vitInfoIcon {
    // height: 100px;
    // width: 50px;
    // height: auto;
    flex-grow: 1;
    max-width: 20px;
}

.fieldContainer {
    width: 100%;
    gap: 24px;
}

.profileContainer {
    flex-direction: row;
    column-gap: 80px;
    row-gap: 10px;
    height: fit-content;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
}

.profilePhoto--left {
    // margin-left: auto;
    // height: 324px;
    height: 100%;
    // width: 324px;
    aspect-ratio: 1/1;

    @media screen and (max-width: 1000px) {
        height: fit-content;
        width: 100%;
        aspect-ratio: 3/1;
    }
}

.profilePhoto--right {
    gap: 24px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-between;
    width: 40%;

    img {
        width: 164px;
        height: 164px;
    }
}

.profilePhoto--right2 {
    gap: 24px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    img {
        width: 335.297px;
        height: 292px;
    }
}



// VIT Modal

.vitTooltip {
    background-color: $neutrals50;

    p {
        color: $neutrals500;

        /* Paragraph/Medium/Regular */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        letter-spacing: -0.32px;
    }
}

.emergencySubtitle {
    color: $primaryYellow500;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;
}

.link {
    color: $secondaryBlue800;
    font-weight: 600;
    transition: color 300ms;
}

.link:hover {
    color: $secondaryBlue900;

    font-weight: 600;
}


.accreditationLevelBanner {
    width: 100%;
    padding: 12px 24px;
    background-color: $secondaryBlue800;
    border-radius: 8px;

    >small {
        color: $shades0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.28px;
    }
}

.uploadSuccess {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    >small {
        color: $primaryPurple500;

        /* Paragraph/Small/SemiBold */
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.28px;
    }
}

.fileUploadLabel {
    color: $primaryBlue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;
}