@import "../../shared/stylesParams.module";

.footer {
  width: 100%;
  // height: 309px;
  height: fit-content;
  background-color: $primary95;
  position: relative;
  padding-top: 40px;
  // padding-bottom: 20px;
}

.footer--dash {
  @extend .footer;
  background-color: #fff;
}

.logo {
  width: 218.04px;
  height: 50px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.1s;
  }
}

.buttonStack {
  column-gap: 52px;
  margin-top: 30px;
}

.footerButton {
  padding: 0px 0px 0px 0px !important;
  min-width: unset;
  color: $primary_20;
  text-decoration-line: underline !important;

  &:hover {
    color: $primary_50;
  }
}

.footerDropdown {
  @extend .footerButton;
  text-decoration-line: none !important;
}

.socialMediasStack {
  column-gap: 29px;
  margin-top: 11px;
}

.footerCompanyInfo {
  text-align: center;
  height: 35px;
  margin-top: 11px;
}

.kepple {
  flex-direction: row;
  gap: 40px;
  width: 100vw;

  >div:first-of-type {
    flex: 1;
    background: $secondaryBlue800;
    border-radius: 0px 100px 0px 0px;
    height: 52px;
  }

  >div:last-of-type {
    flex: 1;
    background: $secondaryBlue800;
    border-radius: 100px 0 0 0;
    height: 52px;
  }

  small {
    color: #003545;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    transition: all 100ms;
  }
  img {
    width: 32px;
    height: 32px;
    transition: all 300ms;
  }
  .keppleText {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  .keppleText:hover {
    small {
      color: #105f77;
    }
    img {
      transform: scale(1.05);
    }
  }  
}

@media screen and (max-width: 1240px) {
  // .footer {
  //   height: 220px;
  // }

  .buttonStack {
    column-gap: 30px;
  }

  .logo {
    height: 35px;
    width: 152.21739196777344px;
  }

  .footerContainer {
    position: unset;
  }

  .kepple {
    justify-content: center;
    padding: 20px;

    >div:first-of-type,
    >div:last-of-type {
      display: none;
    }

    small {
      font-size: 12px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .buttonStack {
    column-gap: 15px;
    margin-top: 12px;
  }

  .logo {
    height: 20px;
    width: 87px;
  }

  // .footer {
  //   height: 175px;
  // }

  .mobile_button_size {
    font-size: 8px;
  }

  .kepple {
    small {
      font-size: 10px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}