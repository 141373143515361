* {
  box-sizing: border-box !important;
}

:root {
  font-family: "Lexend Deca" !important;
}

h1 { font-family: "Lexend Deca" !important; }
h2 { font-family: "Lexend Deca" !important; }
h3 { font-family: "Lexend Deca" !important; }
h4 { font-family: "Lexend Deca" !important; }
h5 { font-family: "Lexend Deca" !important; }
h6 { font-family: "Lexend Deca" !important; }
p { font-family: "Lexend Deca" !important; }
small { font-family: "Lexend Deca" !important; }
a { font-family: "Lexend Deca" !important; }

// Font Size
.display_large {
  font-family: "Lexend Deca" !important;
  font-size: 57px !important;
  line-height: 64px !important;
}

.display_medium {
  font-family: "Lexend Deca" !important;
  font-size: 45px !important;
  line-height: 52px !important;
}

.display_small {
  font-family: "Lexend Deca" !important;
  font-size: 36px !important;
  line-height: 44px !important;
}

.label_large {
  font-family: "Lexend Deca" !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.label_medium {
  font-family: "Lexend Deca" !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.label_small {
  font-family: "Lexend Deca" !important;
  font-size: 11px !important;
  line-height: 12px !important;
}

.title_large {
  font-family: "Lexend Deca" !important;
  font-size: 22px !important;
  line-height: 28px !important;
}

.title_medium {
  font-family: "Lexend Deca" !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.title_small {
  font-size: 14px !important;
  line-height: 20px !important;
  font-family: "Lexend Deca" !important;
}

.headLine_large {
  font-size: 32px !important;
  line-height: 40px !important;
  font-family: "Lexend Deca" !important;
}

.headLine_medium {
  font-size: 28px !important;
  line-height: 36px !important;
  font-family: "Lexend Deca" !important;
}

.headLine_small {
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: "Lexend Deca" !important;
}

// Font Weight
.regular_weight {
  font-weight: 400 !important;
}

.medium_weight {
  font-weight: 500 !important;
}

.semi_bold_weight {
  font-weight: 600 !important;
}

.bold_weight {
  font-weight: 700 !important;
}

.extra_bold_weight {
  font-weight: 800 !important;
}

// Shared Styles
.flex_row_center {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.page_stack {
  row-gap: 102px;
}

.heading1 {
  // @extend .display_medium;
  font-family: "Lexend Deca" !important;
  font-size: 45px !important;
  line-height: 52px !important;
  // @extend .bold_weight;
  font-weight: 700 !important;
}

.heading2 {
  // extra_bold_weight
  font-weight: 800 !important;
  font-family: "Lexend Deca" !important;
  // headLine_small
  font-size: 24px !important;
  line-height: 32px !important;
}

.paragraph1 {
  // headLine_small
  font-size: 24px !important;
  line-height: 32px !important;
  font-family: "Lexend Deca" !important;
  // regular_weight
  font-weight: 400 !important;
}

.paragraph2 {
  // title_medium
  font-family: "Lexend Deca" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  // medium_weight
  font-weight: 500 !important;
}

@media screen and (max-width: 1240px) {
  .page_stack {
    row-gap: 50px;
  }

  .heading1 {
    // @extend .headLine_medium;
    font-size: 28px !important;
    line-height: 36px !important;
    font-family: "Lexend Deca" !important;
    // @extend .semi_bold_weight;
    font-weight: 600 !important;
  }

  .heading2 {
    font-family: "Lexend Deca" !important;
    font-size: 22px !important;
    line-height: 24px !important;
    // semi_bold_weight
    font-weight: 600 !important;
  }

  .paragraph1 {
    // title_medium
    font-family: "Lexend Deca" !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .paragraph2 {
    // title_medium
    font-family: "Lexend Deca" !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .heading1 {
    // @extend .headLine_small;
    font-size: 24px !important;
    line-height: 32px !important;
    font-family: "Lexend Deca" !important;
    // @extend .semi_bold_weight;
    font-weight: 600 !important;
  }

  .paragraph1 {
    // label_small
    font-family: "Lexend Deca" !important;
    font-size: 11px !important;
    line-height: 12px !important;
  }
}
