#errorfallback__stack {
  padding-top: 100px;
}

#errorfallback__ErrorShownPic {
  width: 672px;
  height: 702px;
}

#errorfallback__fallbackInfo {
  font-size: 30px;
}

@media screen and (max-width: 1025px) and (min-width: 780px) {
  #errorfallback__stack {
    padding-top: 280px;
  }

  #errorfallback__ErrorShownPic {
    width: 372px;
    height: 402px;
  }

  #errorfallback__fallbackInfo {
    font-size: 20px;
  }
}

@media screen and (max-width: 780px) {
  #errorfallback__stack {
    padding-top: 100px;
  }

  #errorfallback__ErrorShownPic {
    width: 302px;
    height: 402px;
  }

  #errorfallback__fallbackInfo {
    font-size: 20px;
  }
}
